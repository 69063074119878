import styled from 'styled-components';

export const SideAdsWrapper = styled.div`
  position: sticky;
  top: 120px;

  @media only screen and (max-width: 1540px) {
    display: none;
  }
`;
