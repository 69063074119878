import React from 'react';
import AdsContainer from '@apps/containers/AdsContainer';

import { SideAdsProps } from './props';
import { SideAdsWrapper } from './styles';

export const SideAds: React.FC<SideAdsProps> = (props) => {
  const { adsKeyRight, adsKeyLeft } = props;
  return (
    <SideAdsWrapper>
      <div style={{ left: '-150px', top: '0', position: 'absolute' }}>
        <AdsContainer
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
          }}
          localPlacement={adsKeyLeft}
          imageStyle={{
            width: 120,
            height: 600,
          }}
          checkAdblock={true}
        />
      </div>
      <div style={{ right: '-150px', top: '0', position: 'absolute' }}>
        <AdsContainer
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
          }}
          localPlacement={adsKeyRight}
          imageStyle={{
            width: 120,
            height: 600,
          }}
          checkAdblock={true}
        />
      </div>
    </SideAdsWrapper>
  );
};

export default SideAds;
