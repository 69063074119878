/* eslint-disable @typescript-eslint/no-explicit-any */
import fetch from 'isomorphic-unfetch';

import { BASE_ENDPOINT } from '../constant';

export const getAds = async (
  inPlacement:
    | 'xl_listing'
    | 'sm_listing'
    | 'sm_details'
    | 'home'
    | 'sm_car_detail'
    | 'xl_car_detail'
    | 'news_listing_vertical_left'
    | 'news_listing_vertical_right'
    | 'news_detail_vertical_left'
    | 'news_detail_vertical_right'
    | 'lg_details'
): Promise<any> => {
  let placement: string;
  switch (inPlacement) {
    case 'xl_listing':
      placement = 'news_listing_big';
      break;
    case 'sm_details':
      placement = 'news_detail_small';
      break;
    case 'lg_details':
      placement = 'news_detail_big';
      break;
    case 'sm_listing':
      placement = 'news_listing';
      break;
    case 'home':
      placement = 'news_media_home';
      break;
    case 'news_listing_vertical_left':
      placement = 'news_listing_vertical_left';
      break;
    case 'news_listing_vertical_right':
      placement = 'news_listing_vertical_right';
      break;
    case 'news_detail_vertical_left':
      placement = 'news_detail_vertical_left';
      break;
    case 'news_detail_vertical_right':
      placement = 'news_detail_vertical_right';
      break;
    case 'sm_car_detail':
      placement = 'new_car_detail_small';
      break;
    case 'xl_car_detail':
      placement = 'new_car_detail_big';
      break;
    default:
      placement = 'news_detail_small';
  }

  const url = `${BASE_ENDPOINT}/ads_block_placement/${placement}`;

  try {
    if (!url) return {};
    const res = await fetch(url, {
      method: 'GET',
    });
    return res?.json();
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export default getAds;
